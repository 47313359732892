body {
  margin: 0;
  font-family: 'Pally', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Pally', sans-serif;
}

@media only screen and (max-width: 600px) {
  html {
  font-size: 0.6rem;
  }
};

:global(.css-1nlddgk) {
  width: 350px;
  height: 100%;
  background-color: rgb(255, 249, 241);
}
